.loadercontainer {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.ipe-logo {
    position: absolute;
    width: 150px;
    height: 107px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.butterfly {
    position: absolute;
    width: 134px;
    height: 87px;
    z-index: 2;
    transform-origin: center;
}
.ipelogo2
{
    position: absolute;
    width: 65px;
    height: 65px;
    z-index: 2;
    transform-origin: center;
    left: calc(50% + 51px);
    top: calc(50% - 27px);
    transform: translate(-50%, -50%);
}
.blurred-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(0px);
    opacity: 0;
    transition: opacity 1s ease, filter 1s ease;
    z-index: -1;
}

.dashboardLoader {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease;
}

.top-menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #4A90E2;
    display: flex;
    align-items: center;
    padding: 0 20px;
    z-index: 3;
}

.top-menu img {
    height: 40px;
}

.dashboard-content {
    margin-top: 60px;
    padding: 20px;
}

.left-wing,
.right-wing {
    transform-origin: top center;
    animation: flap 0.3s ease-in-out infinite;
}

@keyframes flap {

    0%,
    100% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-15deg);
    }
}

.blue-bubble {
    position: absolute;
    width: 21px;
    height: 21px;
    background-color: #6169b0;
    left: calc(50% + 33px);
    top: calc(50% - 23px);
    border-radius: 50%;
    background-size: 100% 100%;
    transform: translate(-50%, -50%) scale(1);
    display: none;
    z-index: 10;
}
.fade-out2 {
    opacity: 0;
    transition: opacity 1s ease-out; /* Adjust duration as needed */
    pointer-events: none; /* Optional: prevent interaction during fade-out */
  }